<template>
  <div class="CloseSessionConfirm">
    <div dpadding tcenter>
      <Spacer num="1" />
      <UserAvatar :user="$user" size="120px" mauto />
      <Spacer num="1" />
      <div tcenter>
        <div v-size="`120%`">{{ $locale["close_session_confirm"] }}</div>
        <Spacer num=".5" />
        <p strong>@{{ $user && $user.user }}</p>
      </div>
      <Spacer num="2" />
      <Confirm :confirm="confirm" @cancel="modal && modal.close()" @accept="closeSession" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["modal"],
  data: function() {
    return {
      axios: {
        headers: {
          "use-form": "false",
        },
      },
    };
  },
  methods: {
    closeSession: async function() {
      this.isLoading(true);
      await Go.sleep(250);
      try {
        this.$store.dispatch("removeUser");
        this.deleteUserSuccess({});
      } catch (error) {
        this.deleteUserError(error);
      }
      this.isLoading(false);
    },
    deleteUserError: function(data = {}) {
      this.showMessage(data);
    },
    deleteUserSuccess: function(data = {}) {
      if (Go.is(this.modal.close, "Function")) {
        this.modal.close(() => {
          this.$router.push({ path: "/login" }).catch(() => {});
          this.$router.push({ path: "/" }).catch(() => {});
        });
      }
    },
  },
  computed: {
    confirm: function() {
      return {
        cancel: {
          label: this.$locale["cancel"],
        },
        accept: {
          label: this.$locale["close_session"],
        },
      };
    },
  },
};
</script>
